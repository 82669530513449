<template>
    <div class="management-rate-layout">
   <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />
   <div class="management-rate-container">
      <div class="header-label">
         <div class="label">
            Quản lý tỷ giá
         </div>
         <div class="d-flex-center">
            <div class="synchronization-excel-btn ml-10px" @click="openModal" v-if="checkHasPermission(PERMISSION_CODE.ADMIN_CURRENCY_EDIT)">
               <span class="ml-5px">Thay đổi tỷ giá</span>
            </div>
         </div>
      </div>
      <div class="container-rate">
         <!-- <div class="filter-container mt-20px">
            <div class="mt-10px filter-sale-date-picker d-flex-center-between">
               <div class="filter d-flex-center">
                <v-select :options="keywordTypeList" label="country" v-model="keyword_type" placeholder="Chọn thị trường">
                    <template #search="{attributes, events}">
                        <input
                        class="vs__search"
                        :required="false"
                        v-bind="attributes"
                        v-on="events"
                        />
                    </template>
                </v-select>
               </div>
            </div>
         </div> -->
         <div class="rate-top md-layout md-gutter mt-30px">
            <div class="md-layout-item md-size-30 md-medium-size-40" style="min-width: 400px">
               <div class="layout-right-child top-rank-member">
                  <div class="right-title d-flex-center-between">
                     <div class="head-text">Tỷ giá ngoại tệ <span class="live-button">Live</span></div>
                  </div>
                  <div class="content-right">
                     <div class="md-double-line member-top-list">
                        <!-- <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />
                           <div class="loading-top-rank" v-if="!isLoading && listCountry.length === 0">
                               Không có dữ liệu
                           </div> -->
                        <div class="table-item">
                           <table>
                              <tr>
                                 <th></th>
                                 <th><div class="mb-5px text-400-default">TG đơn hàng</div></th>
                                 <th><div class="mb-5px text-400-default">TG công nợ</div></th>
                              </tr>
                              <tr class="tr-content cursor-pointer" 
                                v-for="(item, index) in listCurrency" :key="index" 
                                @click="filterByCountry(item)"
                                :class="{'active-country': keyword_type == item.country}"
                              >
                                 <th>
                                    <div class="country-item d-flex-center" :title="`Tỷ giá ${item.country}`">
                                       <div class="flag mr-10px">
                                          <img :src="`/img/flag/${item.country ? item.country : 'missing_flag'}.png`" />
                                          <!-- @error="'/img/flag/missing_flag.png'" -->
                                       </div>
                                       <div class="country-name">{{ item.country }} ({{ item.abbr }})</div>
                                    </div>
                                 </th>
                                 <th>
                                    <div class="country-rate-marketing d-flex-center mr-5px text-right">
                                       <template v-if="item.order_rate">
                                            <div class="percent-rate mr-10px" :class="{
                                                'rate-up': item.order_rate.rate_old < item.order_rate.rate_new,
                                                'rate-down': item.order_rate.rate_old > item.order_rate.rate_new
                                            }" >
                                            <template v-if="item.order_rate.percent !== 0">
                                                {{ item.order_rate.percent > 0 ? '+': '' }}{{ item.order_rate.percent }}%
                                            </template>
                                            </div>
                                            <div class="currency-rate" v-html="formatCurrent(item.order_rate.rate_new)"></div>
                                       </template>
                                    </div>
                                 </th>
                                 <th>
                                    <div class="country-rate-debt d-flex-center mr-5px text-right">
                                        <template v-if="item.debt_rate">
                                            <div class="percent-rate mr-10px" :class="item.debt_rate.rate_old < item.debt_rate.rate_new ? 'rate-up' : 'rate-down'" >
                                                {{ item.debt_rate.percent > 0 ? item.debt_rate.percent+'%' : '' }}</div>
                                            <div class="currency-rate" v-html="formatCurrent(item.debt_rate.rate_new)"></div>
                                       </template>
                                    </div>
                                 </th>
                              </tr>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="left-layout layout-item md-layout-item md-size-63">
               <div class="country-selected d-flex-center-between mb-20px">
                    <div class="country d-flex-center-between">
                    <div class="flag-country mr-20px">
                        <img :src="`/img/flag/${selectedOrder.item ? selectedOrder.item.country : 'missing_flag'}.png`" alt="">
                    </div>
                    <div class="rate-country">
                        <div class="currency">{{ selectedOrder.item ? selectedOrder.item.name : '--' }}</div>
                        <div class="small-currency">{{ selectedOrder.item ? selectedOrder.item.abbr + ' / ' +  selectedOrder.item.iso_code : '--' }}</div>
                    </div>
                </div>
                <div class="currency-type">
                    <div class="currency-marketing currcency">
                        <div class="currency-label">Tỷ giá đơn hàng</div>
                        <div class="currency-rate">
                            <!-- <pre>{{ selectedOrder.item }}</pre> -->
                            <template v-if="selectedOrder.item && selectedOrder.item.order_rate">
                                <div class="currency-now d-flex-center" :set="order = selectedOrder.item.order_rate">
                                        <div class="rate-now">{{ formatCurrent(order.rate_new)}}</div>
                                        <div class="percent-rate" :class="{
                                            'rate-down': order.rate_new < order.rate_old,
                                            'rate-up': order.rate_new > order.rate_old
                                            }">
                                            <img class="mr-5px" src="/img/flag/down-icon-rate.svg" alt="">
                                            {{ roundNumberFormat(((order.rate_new - order.rate_old) / order.rate_old) * 100) + '%'}}
                                        </div>
                                </div>

                                <div class="date-cu" v-html="formatDate(order)"></div>
                            </template>
                            <template v-else>
                                <div class="unknow-item">--</div>
                            </template>
                        </div>
                    </div>
                    <div class="currency-deb currcency">
                        <div class="currency-label">Tỷ giá công nợ</div>
                        <div class="currency-rate">
                            <template v-if="selectedDebt.item && selectedDebt.item.debt_rate">
                                <div class="currency-now d-flex-center" :set="debt = selectedDebt.item.debt_rate">
                                        <div class="rate-now">{{ formatCurrent(debt.rate_new)}}</div>
                                        <div class="percent-rate" :class="{
                                            'rate-down': debt.rate_new < debt.rate_old,
                                            'rate-up': debt.rate_new > debt.rate_old
                                            }">
                                            <img class="mr-5px" src="/img/flag/down-icon-rate.svg" alt="">
                                            {{ roundNumberFormat(((debt.rate_new - debt.rate_old) / debt.rate_old) * 100) + '%'}}
                                        </div>
                                </div>
                                <div class="date-cu" v-html="formatDate(debt)"></div>
                            </template>
                            <template v-else>
                                <div class="unknow-item">--</div>
                            </template>
                        </div>
                    </div>
                </div>
               </div>
               <!-- chart -->
               <div class="chart-rate md-layout">
                <LineChartData :chartData="lineChart.chartData" 
                  :chartOptions="lineChart.chartOptions" 
                  :styles="{ height: '250px' }"
                  />
               </div>
               <!-- Tỷ giá đơn hàng & Tỷ giá công nợ -->
               <div class="table-rate-compare">
                  <div class="table-compare compare-rate-marketing">
                     <div class="table-header-label">
                        Tỷ giá đơn hàng
                     </div>
                     <TableEasy
                        :columns="columns"
                        :tableData="selectedOrder.list"
                        :scrollWidth="0"
                        :borderAround="false"
                        :borderX="true"
                        :borderY="true"
                        />
                        <div v-if="selectedOrder.list.length == 0" class="empty-data">Không có dữ liệu.</div>
                  </div>
                  <div class="table-compare compare-rate-debt">
                     <div class="table-header-label">
                        Tỷ giá công nợ
                     </div>
                     <TableEasy
                        :columns="columns"
                        :tableData="selectedDebt.list"
                        :scrollWidth="0"
                        :borderAround="false"
                        :borderX="true"
                        :borderY="true"
                        />
                        <div v-if="selectedDebt.list.length == 0" class="empty-data">Không có dữ liệu.</div>
                  </div>
               </div>
            </div>

         </div>
      </div>
   </div>
   <Teleport to="#menu-card">
      <div class="menu-item menu-item-active">Quản lý tỷ giá</div>
   </Teleport>
   <change-rate-modal @reload-data="reloadData" />
</div>
  </template>
  
  <script>
  import ChangeRateModal from "@/pages/Components/Modal/ChangeRate"
  import AdminService from "../../../../store/services/AdminService";
  import vue2DatePicker from "@/mixins/config-vue2-datepicker";
  import DatePicker from 'vue2-datepicker';
  import moment from "moment";
  import TableEasy from "../TableEasy"
  import LineChartData from "../Chart/LineChartData"
  import {PERMISSION_CODE} from "../../../../../src/const/const";
  import { helpers } from "../../../../helper/helpers";
  const date = new Date();

  export default {
    components: {
        ChangeRateModal,
        // DatePicker,
        TableEasy,
        LineChartData
    },
    mixins: [vue2DatePicker],
    props: {
        
    },
    data() {
        return {
            isLoading: false,
            marketCurrency: [],
            timeRange: new Date(date.getFullYear(), date.getMonth(), 1),
            dateSelection: '',
            keyword_type: '',
            keywordTypeList: [],
            columns: [
                {
                    field: "date",
                    key: "a",
                    title: "Ngày",
                    align: "center",
                    // renderBodyCell: ({row, column, rowIndex}, h) => {
                    //     return (
                    //         <div>{rowIndex + this.pagingParams.perPage * (this.pagingParams.page - 1) + 1}</div>
                    //     );
                    // }
                },
                {
                    field: "rate",
                    key: "b",
                    title: "Tỷ giá",
                    align: "right",
                    // renderBodyCell: ({row, column, rowIndex}, h) => {
                    //     return (
                    //         <div>{rowIndex + this.pagingParams.perPage * (this.pagingParams.page - 1) + 1}</div>
                    //     );
                    // }
                },
                {
                    field: "pecent_now_rate",
                    key: "c",
                    title: "% so với hiện tại",
                    align: "right",
                    renderBodyCell: ({row, column, rowIndex}, h) => {
                        let className = '';
                        let operatorLetter = '';
                        // if(row.rate < row.rate_prev){
                        //     className = 'rate-down';
                        // }
                        if(row.pecent_now_rate > 0){
                            className = 'rate-up';
                            operatorLetter = '+'
                        } else if(row.pecent_now_rate < 0) {
                            // số âm
                            className = 'rate-down';
                        }

                        // if(row.rate > row.rate_prev){
                        //     className = 'rate-up';
                        // }
                        return (
                            <div class={className}>{operatorLetter + row.pecent_now_rate}%</div>
                        );
                    }
                },
                {
                    field: "pecent_prev_rate",
                    key: "d",
                    title: "% so với kế trước",
                    align: "right",
                    renderBodyCell: ({row, column, rowIndex}, h) => {
                        let className = '';
                        let operatorLetter = '';
                        if(row.pecent_prev_rate > 0){
                            className = 'rate-up';
                            operatorLetter = '+'
                        } else if(row.pecent_prev_rate < 0) {
                            // là số âm
                            className = 'rate-down';
                        }
                        return (
                            <div class={className}>{operatorLetter + row.pecent_prev_rate}%</div>
                        );
                    }
                }
            ],
            tableDataMaketing: [],
            tableDataDebt: [],
            listCountry: [],
            lineChart: {
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Tỷ giá đơn hàng',
                            data: [],
                            borderColor: '#F765A3',
                            backgroundColor: '#F765A3',
                            borderWidth: 2,
                            lineTension: 0.5,
                            fill: 1,
                            tension: 0.5,
                            yAxisID: 'y',
                        },
                        {
                            label: 'Tỷ giá công nợ',
                            data: [],
                            borderColor: '#16BFD6',
                            backgroundColor: '#16BFD6',
                            borderWidth: 2,
                            lineTension: 0.5,
                            fill: 1,
                            tension: 0.5,
                            yAxisID: 'y',
                        }
                    ]
                },
                chartOptions: {
                legend: {
                    display: true
                },
                plugins: {
                    legend: {
                    align : 'end',
                    paddingBottom: 30
                    },
                    datalabels: {
                    display: false, 
                    },
                },
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,
                responsive: true,
                maintainAspectRatio: false,
                },
            },
            listCurrency: [],
            selectedDebt: {
                item: null,
                list: []
            },
            selectedOrder: {
                item: null,
                list: []
            },
            PERMISSION_CODE: PERMISSION_CODE,
            helpers: helpers
        }
    },

    watch: {
    //    'keyword_type'(newV){
    //         this.filterByCountry(newV);
    //     }
    },

    created(){
        if(!this.checkHasPermission(PERMISSION_CODE.ADMIN_CURRENCY_MANAGE)){
            window.location.href = '/';
            return;
        }
        this.getMarketCurrency();
        this.getListMarketCurrency();
    },
  
    methods: {
        async getMarketCurrency(){
            this.isLoading = true;
            this.marketCurrency = [];
            await AdminService.getMarketOnCurrency().then(res => {
            if(res.status && res.data){
                this.marketCurrency = res.data
            }
          });
          this.isLoading = false;
        },

        async getListMarketCurrency(){
            this.listCurrency = [];
            this.keywordTypeList = [];
            const today = moment().format('YYYY-MM-DD');
            await AdminService.getListMarketCurrency().then(res => {
            if(res.status && res.data){
                for (const key in res.data) {
                    if (Object.hasOwnProperty.call(res.data, key) && res.data[key].active_currency.type == 1) {
                        let element = res.data[key].active_currency;
                        const debt_rate_item = res.data.find(item => item.active_currency.iso_code == element.iso_code &&
                            item.active_currency.type == 2
                        );

                        // tỷ giá công nợ
                        const lastChangeDebt = debt_rate_item.changes.find(item => item.id !== debt_rate_item.active_currency.id && item.start_time <= today && item.end_date >= today);
                        // tỷ giá đơn hàng
                        const lastChangeOrder = res.data[key].changes.find(item => item.id !== element.id && item.start_time <= today && item.end_date >= today);

                        const item = {
                            ...element,
                            'order_rate': {
                                'rate_new': element.rate,
                                'rate_old': lastChangeOrder?.rate ?? 0,
                                'start_date': lastChangeOrder?.start_date ?? null,
                                'created_at': element.created_at,
                                'changes': res.data[key].changes,
                                'percent': this.roundNumberFormat(((element.rate - lastChangeOrder?.rate ?? 0) / lastChangeOrder?.rate ?? 0) * 100)
                            },
                            'debt_rate': {
                                'rate_new': debt_rate_item?.active_currency?.rate ?? 0,
                                'rate_old': lastChangeDebt?.rate ?? 0,
                                'start_date': lastChangeDebt?.start_date ?? null,
                                'created_at': element.created_at,
                                'changes': debt_rate_item.changes,
                                'percent': this.roundNumberFormat(((element.rate - lastChangeDebt?.rate ?? 0) / lastChangeDebt?.rate ?? 0) * 100)
                            }
                        }
                        this.listCurrency.push(item);
                    }
                }
                this.listCurrency = this.listCurrency.reverse();
                this.filterByCountry(this.listCurrency[0])
            }
          });
        },


        openModal(){
            this.$modal.show('change-rate-modal', this.marketCurrency);
        },

        changeDate(value) {
            let start_date = moment(value).startOf('month');
            let end_date = moment(value).endOf('month');

            this.dateSelection = start_date.format('DD/MM/YYYY') + ' - ' + end_date.format('DD/MM/YYYY');
            this.timesheetFilterValue = {
                start_date: start_date.format('YYYY-MM-DD'),
                end_date: end_date.format('YYYY-MM-DD'),
            }
            // =====================getlistCountry================ //
        },

        async filterByCountry(data){
            if(this.keyword_type == data.country){
                return;
            } 
            this.isLoading = true;
            if(data){
                this.keyword_type = data.country;
                await AdminService.getDetailMarketCurrency({country: data.country}).then(res => {
                    if(res.status && res.data){
                        this.generateData(res.data, data.country);
                    }
                });
            } else {
                this.resetData();
            }
            this.isLoading = false;
        },

        generateData(data, countrySelected){
            this.resetData();
            const lengthData = data.length - 1;
            let firstDate = moment(data[0]?.start_date ?? data[0].created_at);
            let lastDate = moment(data[lengthData]?.start_date ?? data[lengthData].created_at);

            let currentDate = firstDate.clone();
            while (currentDate.isSameOrBefore(lastDate)) {
                this.lineChart.chartData.labels.push(currentDate.format('DD/MM/YYYY'));
                currentDate.add(1, 'days');
            }

            let activeItemOrder = null;
            let activeItemDebt = null;

            for (const key in this.listCurrency) {
                const item = this.listCurrency[key];
                if(item.country == countrySelected && item.order_rate){
                    activeItemOrder = {...item, ...{order_rate : item.order_rate}};
                }

                if(item.country == countrySelected && item.debt_rate){
                    activeItemDebt = {...item, ...{debt_rate : item.debt_rate}};
                }
            }

            this.selectedDebt.item = activeItemDebt;
            this.selectedOrder.item = activeItemOrder;

            let preOrderRate = [];
            let preDebtRate = [];
            
            let labels = [];
            for (let index = 0; index < data.length; index++) {
                // console.log(data);
                // return;
                const element = data[index];
                if(element.start_date == null){
                    element.start_date = moment().format('YYYY-MM-DD');
                }
                
                labels.push(moment(element.start_date).format('YYYY-MM-DD'));

                if(element.type == 1){
                    preOrderRate.push(element);
                } else {
                    preDebtRate.push(element);
                }
            }

            let labelChart = [...new Set(labels)];
            labelChart.sort(function (a, b) {
                return new Date(a) - new Date(b);
            });
            
            this.lineChart.chartData.labels = labelChart.map((item) => {
                this.lineChart.chartData.datasets[1].data.push(0);
                this.lineChart.chartData.datasets[0].data.push(0);
                return moment(item).format('DD/MM/YYYY');
            });
            this.logicMakeData(preOrderRate);
            this.logicMakeData(preDebtRate);
          
        },

        logicMakeData(data){
            data.sort(function (a, b) {
                return new Date(a.start_date) - new Date(b.start_date);
            });
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                const elementPrev = data[index - 1];

                // let exchangeRateDate = '';
                let pecent_prev_rate = 0
                if(elementPrev){
                    // exchangeRateDate = moment(elementPrev.start_date).format('DD/MM') + ' - ';
                    pecent_prev_rate = ((element.rate - elementPrev.rate) / elementPrev.rate) * 100;
                } 

                // exchangeRateDate += moment(element.start_date).format('DD/MM');

                let exchangeRate = {
                    date : moment(element.start_date).format('DD/MM') +  (element.end_date ? ' - ' + moment(element.end_date).format('DD/MM') : ''),
                    rate : element.rate,
                    rate_prev: elementPrev?.rate ?? 0,
                    pecent_now_rate : 0,
                    pecent_prev_rate : this.roundNumberFormat(pecent_prev_rate)
                };
                let activeItemDebt = this.selectedDebt.item;
                let activeItemOrder = this.selectedOrder.item;


                const indexChart = this.lineChart.chartData.labels.findIndex((item) => item === moment(element.start_date).format('DD/MM/YYYY'));
                if(element.type == 1){
                    this.lineChart.chartData.datasets[0].data[indexChart] = element.rate;
                    exchangeRate.pecent_now_rate = this.roundNumberFormat(activeItemOrder ? (((element.rate - activeItemOrder.order_rate.rate_new) / activeItemOrder.order_rate.rate_new) * 100).toFixed(2) : 0);
                    this.selectedOrder.list.push(exchangeRate);
                } else {
                    this.lineChart.chartData.datasets[1].data[indexChart] = element.rate;
                    exchangeRate.pecent_now_rate = this.roundNumberFormat(activeItemDebt ? (((element.rate - activeItemDebt.debt_rate.rate_new) / activeItemDebt.debt_rate.rate_new) * 100).toFixed(2): 0);
                    this.selectedDebt.list.push(exchangeRate);
                }
            }
            this.mapDataChart(this.lineChart.chartData.datasets[0].data)
            this.mapDataChart(this.lineChart.chartData.datasets[1].data)
        },

        formatDate(data){
            let date = data.start_date;
            return date ? `(áp dụng từ ${moment(date).format('DD/MM')})` : '--';
        },

        resetData(){
            this.lineChart.chartData.datasets[0].data = [];
            this.lineChart.chartData.datasets[1].data = [];
            this.lineChart.chartData.labels = [];
            this.selectedDebt = {
                item: null,
                list: []
            }
            this.selectedOrder = {
                item: null,
                list: []
            }
        },

        checkHasPermission(name){
            return this.$store.state.auth.permission.find(item => item.name === name);
        },

        roundNumberFormat(number){
            if (!isFinite(number)) {
                return 0;
            }
            let roundedNumber = Math.round(number * 100) / 100;

            if (Number.isInteger(roundedNumber)) {
                return roundedNumber;
            } else {
                return roundedNumber.toFixed(2);
            }
        },

        mapDataChart(arr){
            for (let i = 1; i < arr.length; i++) {
                if (arr[i] === 0) {
                    arr[i] = arr[i - 1];
                }
            }
            return arr;
        },

        formatCurrent(currency){
            return helpers.formatCurrency(currency).split(',').join('.');
        },
        
        reloadData(){
            this.getMarketCurrency();
            this.getListMarketCurrency();
        }
    }
  };
  </script>
<style lang="scss">
    $color-text: rgba(16, 24, 64, 1);
    .wrapper{
        height: 100%;
    }
    .management-rate-layout{
    .ve-table-layout{
        min-height: 300px;
    }
    .v-select{
        min-width: 200px;
    }
        .rate-top{
            display: flex;
            justify-content: space-between;
        }
        .left-layout {
            // display: flex;
            // justify-content: space-between;
            // align-items: baseline;
            .flag-country{
                width: 80px;
                img{
                    border-radius: 5px;
                }
            }
        }
        .layout-right-child {
            background: #fff;
            border: 1px solid rgba(145, 199, 230, 1);
            border-radius: 4px;
            margin-bottom: 20px;
            .right-title {
            padding: 10px 20px;
            border-bottom: 1px solid #cfcfcf;
            min-height: 40px;
            background: linear-gradient(#a0e8ff,#8ac7f3);
            .head-text{
                text-transform: uppercase;
                color: rgba(16, 24, 64, 1);
                font-weight: 600;
                font-size: 14px;
                .live-button{
                    border: 1px solid red;
                    border-radius: 4px;
                    padding: 2px 8px 2px 16px;
                    margin-left: 10px;
                    font-size: 11px;
                    font-weight: 500;
                    text-transform: capitalize;
                    color: red;
                    background: #fff;
                    position: relative;
                    &::after{
                        position: absolute;
                        content: "";
                        top: 50%;
                        left: 8px;
                        width: 6px;
                        height: 6px;
                        background: red;
                        border-radius: 100%;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                    }
                }
            }
            }
            
            .content-right{
                padding-top: 10px;
                min-height: 330px;
                background: linear-gradient(#f1fcff, #e0f2ff);
            }
        }
        .table-item{
            table{
                width: 100%;
                border-collapse: collapse;
                .active-country{
                    background: #E9FFE9;
                }
                .tr-content{
                    th{
                        &:first-child{
                            padding-left: 10px;
                        }
                        &:last-child{
                            padding-right: 10px;
                        }
                    }
                    &:hover{
                        background: #E9FFE9;
                    }
                    border-top: 1px solid #d8d8d8;
                    .percent-rate{
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
                .country-item{
                    font-weight: 500;
                    padding: 10px 0;
                    .country-name{

                    }
                    .flag{
                        img{
                            width: 20px;
                        }
                    }
                }
            }
        }
        .keyword-type{
            width: 200px;
            border: 1px solid #e9e8e8;
            border-radius: 5px;
        }
        .rate-country{
            .currency{
                font-size: 22px;
                font-weight: 500;
                text-transform: capitalize;
            }
            .small-currency {
                font-size: 13px;
                color: rgba(82, 87, 111, 1);
                font-weight: 500;
            }
        }
        .currency-type{
            display: flex;
            grid-gap: 50px;
            text-align: center;
            line-height: 30px;
            .currency-label {
                font-size: 15px;
                font-weight: 500;
            }
            .currency-now{
                .rate-now{
                    font-size: 23px;
                    font-weight: 600;
                    margin-right: 10px;
                }
                .percent-rate{
                    border-radius: 5px;
                    padding: 2px 8px;
                    color: #fff;
                    img{
                        width: 20px;
                    }
                }
                .rate-down {
                    background: rgba(255, 73, 73, 1);
                }
                .rate-up{
                    background: rgba(35, 192, 21, 1);
                    img{
                        rotate: 180deg;
                    }
                }
            }
            .date-cu {
                font-size: 13px;
                color: rgba(16, 24, 64, 1);
            }
        }
        .chart-rate{
            height: 300px;
            width: 100%;
        }
        .table-rate-compare{
            display: flex;
            justify-content: space-between;
            grid-gap: 40px;
            margin-top: 40px;
            .table-compare {
                width: 50%;
                position: relative;
                .table-header-label {
                    font-size: 18px;
                    font-weight: 500;
                    margin: 10px 0;
                }
                .empty-data {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 200px;
                    width: 100%;
                    color: #666;
                    font-size: 16px;
                    border: 1px solid #eee;
                    border-top: 0;
                    position: absolute;
                    top: 30%;
                }
            }
        }
        .rate-up {
            color: rgba(6, 177, 33, 1);
        }
        .rate-down {
            color: rgba(241, 23, 23, 1)
        }
    }
</style>
  